import {
  IonContent,
  IonFooter,
  IonIcon,
  IonLoading,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { useMemo } from 'react';

import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, FlexColumn } from '~/components/defaultUIComponents';
import JobsInProject from '../components/JobsInProject';
import OptionsInProject from '../components/OptionsInProject';
import OrdersInProject from '../components/OrdersInProject';
import Toolbar from '../components/Toolbar/Toolbar';
import { useTranslate } from '~/i18n/translate';
import { useEmployeeId } from '~/state/auth';
import { useCreateTodos } from '~/api/DayplanApi';
import routes from '../constants/routes.json';
import { useDayplanStore } from '~/state/dayplan';
import { useSummedOrderedProject } from '~/api/OrderingApi';
import ProjectPicturePopup from '~/components/ProjectPicturePopup';

type ProjectPageProps = RouteComponentProps<{ id: string }>;

function ProjectPage(props: ProjectPageProps) {
  const history = useHistory();
  const translate = useTranslate();
  const id = props.match?.params?.id;
  const projectId = Number(id);
  const { data: currentSummedOrderProject, isLoading } =
    useSummedOrderedProject({ projectId, enabled: !!projectId });

  const { mutate: createTodos } = useCreateTodos({
    onSuccess: () => {
      history.push(routes.DAY_PLAN);
      useDayplanStore.setState(useDayplanStore.getInitialState());
    },
  });

  const { todos, isEditingTodos } = useDayplanStore((state) => ({
    todos: state.todos,
    isEditingTodos: state.todos.length > 0,
  }));

  const employeeId = useEmployeeId();

  const displayedProjectName = useMemo(
    () => currentSummedOrderProject?.project?.name || '',
    [currentSummedOrderProject],
  );

  return (
    <IonPage>
      <Toolbar>{translate('project.toolbar.title')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        <FlexColumn>
          <ProjectPicturePopup
            projectId={projectId}
            projectName={displayedProjectName}
            prefix={translate('project.project') + ':'}
          />
          <OrdersInProject summedProjectOrder={currentSummedOrderProject} />
          <JobsInProject
            projectId={currentSummedOrderProject?.project?.id}
            hasOptions={!!currentSummedOrderProject?.project?.options?.length}
          />
          <OptionsInProject summedProjectOrder={currentSummedOrderProject} />
        </FlexColumn>
      </IonContent>
      {isEditingTodos && (
        <IonToolbar>
          <IonFooter>
            <Button onClick={() => createTodos({ todos, employeeId })}>
              {translate('project.moveInDayplan')}
              <IonIcon icon={arrowForward} />
            </Button>
          </IonFooter>
        </IonToolbar>
      )}
    </IonPage>
  );
}

export default ProjectPage;
