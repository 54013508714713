import React, { useState, useEffect } from 'react';
import { IonImg } from '@ionic/react';
import { useTranslate } from '../i18n/translate';

interface ProjectPictureProps {
  initialImage: string | null;
  projectId: number;
}

const ProjectPicture: React.FC<ProjectPictureProps> = ({ initialImage }) => {
  const translate = useTranslate();
  const [image, setImage] = useState<string | null>(initialImage);
  useEffect(() => {
    if (initialImage) {
      setImage(initialImage);
    }
  }, [initialImage]);
  return (
    <div
      className="ion-padding"
      style={{ position: 'relative', width: '100%', height: '100%' }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {image ? (
          <IonImg
            src={image}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '2px dashed gray',
            }}
          >
            <span>{translate('project.main_image')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectPicture;
