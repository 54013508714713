import React, { useState, useEffect } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import ProjectPictureApi from '../api/ProjectPictureApi';
import ProjectPicture from './ProjectPicture';
import { useTranslate } from '~/i18n/translate';
import { close } from 'ionicons/icons';

interface ProjectPictureData {
  picture: string | null;
}

const ProjectPicturePopup: React.FC<{
  projectId: number;
  projectName: string;
  prefix: string;
}> = ({ projectId, projectName, prefix }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [projectPicture, setProjectPicture] =
    useState<ProjectPictureData | null>(null);
  const translate = useTranslate();

  useEffect(() => {
    if (projectId) {
      void ProjectPictureApi.queries
        .getProjectPicture(projectId)
        .then((data: ProjectPictureData | null) => {
          setProjectPicture(data);
        });
    }
  }, [projectId]);

  return (
    <>
      <span
        className="break-words pl-4 mt-4 font-bold"
        style={{ fontSize: 'x-large' }}
      >
        {prefix}{' '}
        <span
          style={{
            cursor: 'pointer',
            color: '#2563eb',
          }}
          onClick={() => setIsOpen(true)}
        >
          {projectName}
        </span>
      </span>

      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{translate('project.main_image')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)}>
                <IonIcon
                  icon={close}
                  style={{ color: 'red', fontSize: '24px' }}
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <ProjectPicture
            initialImage={projectPicture?.picture || null}
            projectId={projectId}
          />
        </IonContent>
      </IonModal>
    </>
  );
};

export default ProjectPicturePopup;
