import React, { useState } from 'react';
import { IonAlert } from '@ionic/react';
import { useHistory } from 'react-router';
import routes from '../../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { WorkingState } from '~/types';
import { Button, FlexColumn, FlexRow } from '../defaultUIComponents';
import {
  useCheckEndPause,
  useCheckEndWork,
  useEndWork,
  usePauseWork,
  useStartWork,
  useWorkingState,
} from '~/api/TimetrackingApi';
import { useCompanySettigns } from '~/api/CompanySettingsApi';
import { useLogout } from '~/api/AuthApi';
import { useEmployeeId } from '~/state/auth';

function secondsToMinutesAndSeconds(seconds: number): [number, number] {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return [minutes, remainingSeconds];
}

function createEndPauseMessage(minutes: number, seconds: number): string {
  return `Versuchen Sie es in ${minutes} Minuten und ${seconds} Sekunden nochmal`;
}

function createEndWorkMessage(minutes: number, seconds: number): string {
  return `Sie benötigen noch ${minutes} Minuten und ${seconds} Sekunden Pausenzeit bevor ein Logout möglich ist`;
}

interface TimeTrackingButtonsProps {}

export const TimeTrackingButtons: React.FC<TimeTrackingButtonsProps> = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { data: workingState = WorkingState.NOTHING } = useWorkingState();
  const { data: companySettings } = useCompanySettigns();
  const t = useTranslate();
  const history = useHistory();

  const { mutate: startWork, isPending: startWorkPending } = useStartWork();
  const { mutate: checkEndWork } = useCheckEndWork();
  const { mutate: checkEndPause } = useCheckEndPause();
  const employeeId = useEmployeeId();

  const { mutate: pauseWork, isPending: pauseWorkPending } = usePauseWork({
    onSuccess: () => {
      if (history.location.pathname.startsWith('/run')) {
        history.push(routes.RUNNING_JOBS);
      }
    },
  });
  const { mutate: handleEndWork, isPending: endWorkPending } = useEndWork();
  const { mutate: logout } = useLogout();

  const isLoading = startWorkPending || pauseWorkPending || endWorkPending;

  const endWork = () => {
    if (companySettings?.logout_on_leave) {
      return logout();
    }
    handleEndWork();
  };

  function startWorkIfBreakIsValid(workingState: WorkingState) {
    if (workingState !== WorkingState.PAUSE) {
      startWork();
    }
    checkEndPause(employeeId, {
      onSuccess: (tempCheckNum) => {
        if (tempCheckNum > 0) {
          const [minutes, seconds] = secondsToMinutesAndSeconds(tempCheckNum);
          const message = createEndPauseMessage(minutes, seconds);
          setAlertMessage(message);
          setShowAlert(true);
        } else {
          startWork();
        }
      },
    });
  }

  function endWorkIfTotalBreakIsValid() {
    checkEndWork(employeeId, {
      onSuccess: (tempCheckNum) => {
        if (tempCheckNum > 0) {
          const [minutes, seconds] = secondsToMinutesAndSeconds(tempCheckNum);
          const message = createEndWorkMessage(minutes, seconds);
          setAlertMessage(message);
          setShowAlert(true);
        } else {
          endWork();
        }
      },
    });
  }

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('logout.alert.header')}
        message={alertMessage}
        buttons={['OK']}
      />
      {workingState === WorkingState.WORKING ? (
        <FlexRow>
          <FlexColumn>
            <Button
              onClick={() => pauseWork()}
              disabled={isLoading}
              className="ml-1 mr-1 h-10"
            >
              {t('timeTracking.pause')}
            </Button>
          </FlexColumn>
          <FlexColumn>
            <Button
              onClick={() => endWorkIfTotalBreakIsValid()}
              disabled={isLoading}
              className="mr-1  h-10"
            >
              {t('timeTracking.end')}
            </Button>
          </FlexColumn>
        </FlexRow>
      ) : (
        <Button
          onClick={() => startWorkIfBreakIsValid(workingState)}
          disabled={isLoading}
          className="ml-1 mr-2  h-10"
        >
          {t(
            workingState === WorkingState.NOTHING
              ? 'timeTracking.start'
              : 'timeTracking.pauseEnd',
          )}
        </Button>
      )}
    </>
  );
};
