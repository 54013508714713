import { useQuery } from '@tanstack/react-query';
import { QueryOptions, unwrap } from './common';
import axios from 'axios';

export enum ProjectPictureKeys {
  PROJECT_PICTURE = 'PROJECT_PICTURE',
}

export type ProjectPicture = {
  id: number;
  picture: string;
};

const queries = {
  async getProjectPicture(projectId: number) {
    if (!projectId) {
      return null;
    }
    const res = await axios.get<ProjectPicture>(`/project/pic/${projectId}/`);
    return unwrap(res);
  },
};
const hooks = {
  useProjectPictureQuery(
    projectId: number,
    options?: QueryOptions<ProjectPicture | null>,
  ) {
    return useQuery({
      queryKey: [ProjectPictureKeys.PROJECT_PICTURE, projectId],
      queryFn: () => queries.getProjectPicture(projectId),
      ...(options ?? {}),
    });
  },
};

const ProjectPictureApi = {
  queries,
  hooks,
};

export default ProjectPictureApi;
